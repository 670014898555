import React from 'react'

import { Link } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'

import { Section, Button, Title, Text, Box } from '../components/Core'
import PageWrapper from '../components/PageWrapper'
import Contact from '../sections/common/Contact'
import imgS2 from '../assets/image/details/berlinomania/BCards_Pile.jpg'
import imgS3 from '../assets/image/details/berlinomania/Bcards.webp'
import Square from '../assets/image/details/berlinomania/BlueCrop.jpg'
import vid from '../assets/videos/bvideomock.mp4'
import cover from '../assets/image/details/crypto-app/Moodboard.jpg'
import moodboard from '../assets/image/details/berlinomania/MoodboardBerlinomania.jpg'


const CaseStudies = () => {
  return (
    <>
      <PageWrapper>
        <Container
          fluid
          className="px-sm-5"
          css={`
            margin-top: 92px;
          `}
        >
          <Row className="justify-content-center align-items-center">
            <Col xs="12" md="6">
              <Container className="mb-5 mt-5 col-12">
                <img src={cover} alt="" className='w-100 img-fluid' />
              </Container>
            </Col>
          </Row>
        </Container>
        ``
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="8">
                <Text variant="tag">BRANDING</Text>
                <Title variant="secSm" className="my-4">
                  Project Overview{' '}
                </Title>
                <Text
                  variant="p"
                  css={`
                    max-width: 750px;
                  `}
                >
                  {' '}
                  During the pandemic, tour guides were among the most impacted
                  group in the industry. My Client - a tour guide in Berlin, was
                  very busy before the hit of the pandemic but suddenly lost all
                  their bookings. To stay afloat, they had to adapt on the fly
                  and change their operating models from mainly offline to
                  online. My role was to create a visually cohesive brand and
                  digitalize their business. I delivered corporate design,
                  including logo and responsive website.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <div className="mt-lg-3">
          <Container>
            <Row>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Client</Text>
                <Title variant="cardBig" className="mt-3">
                  Berlinomania
                </Title>
              </Col>
              <Col lg="4" className="mb-4 mb-lg-0">
                <Text variant="tag">Time Scope</Text>
                <Title variant="cardBig" className="mt-3">
                  2 Months
                </Title>
              </Col>
              {/* <Col lg="4" className="mb-16">
                <Button onClick={handleLiveWorkClicked} arrowRight>
                  Live work
                </Button>
              </Col> */}
            </Row>
          </Container>
        </div>
        <Container>
          <Col xs="12" className="mb-5 mt-5">
            <img
              src={moodboard}
              alt="pictures of Berlin architecture"
              className="img-fluid w-100"
            />
          </Col>
        </Container>
        <Section className="pt-4 pb-0">
          <Container>
            <Title variant="secSm" className="mb-5 pb-lg-4">
              Process
            </Title>
            <Row>
              <Col lg="6" className="mb-5 pr-lg-5">
                <Title variant="cardBig" className="mb-4">
                  1. Briefing
                </Title>
                <Text variant="p">
                  Experienced city guide and history expert Team offers tours
                  through Berlin and Potsdam on various topics: history, art,
                  and culture. Guided tours are available for groups as well as
                  individuals. Tours are available in Polish and German
                  language. The website should be minimalistic and easy to
                  navigate for people of all ages.
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pl-lg-5">
                <Title variant="cardBig" className="mb-4">
                  2. Deliverables
                </Title>
                <Text variant="p">
                  - Moodboard <br />
                  - Creating UI/UX <br />
                  - Copy Text Edit <br />
                  - Photo Edit <br />
                  - Corporate Design <br />
                  - Business Cards <br />
                  - Building Responsive Website <br />
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pr-lg-5">
                <Title variant="cardBig" className="mb-4">
                  3. Strategy
                </Title>
                <Text variant="p">
                  I decided to build the website with WordPress, as one of the
                  key objectives was to use a CSM the client has already been
                  familiar with. The goal was to deliver the product promptly
                  while satisfying the design goals and aesthetics.
                </Text>
              </Col>
              <Col lg="6" className="mb-5 pl-lg-5">
                <Title variant="cardBig" className="mb-4">
                  4. Key Learnings
                </Title>
                <Text variant="p">
                  To speed up the process after creating a quick designs in
                  Adobe XD, I jumped almost straight to WordPress, only to
                  discover that it's quite limiting and customizing a theme is
                  difficult. Also iterating on designs in the code is much
                  slower than using design tools.
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <Section className="mt-lg-5">
          <Container>
            <Row>
              <Col lg="12" className="mb-5 mt-5 pr-lg-4">
                <img src={imgS3} alt="" className="img-fluid w-100" />
              </Col>
              <Col lg="12" className="mb-5 mt-5 pr-lg-4">
                <img src={imgS2} alt="" className="img-fluid w-100" />
              </Col>
              {/* <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">
                <img src={imgS2} alt="" className="img-fluid w-100" />
              </Col> */}
              {/* <Col lg="6" className="mb-5 mb-lg-0 pr-lg-4">
                <img src={mobileHorizontal} alt="" className="img-fluid w-100" />
              </Col> */}
              <Col lg="12" className="mb-5 mb-lg-0 pr-lg-4">
                <img src={Square} alt="" className="img-fluid w-100" />
              </Col>

              <Col xs="12" className="mb-5 mt-5">
                <video
                  className="video-size"
                  style={{ maxWidth: '100%' }}
                  controls
                >
                  <source
                    src={vid}
                    type="video/mp4"
                    className="img-fluid w-100"
                  />
                </video>
              </Col>
            </Row>
          </Container>
        </Section>
        {/* <Section mt="22%" bg="dark">
          <Container>
            {/* <Row

              css={`
                margin-top: -40%;
              `}
            >
              { <Col xs="12" className="mb-5">
                <img src={imgS5} alt="" className="img-fluid w-100" />
              </Col>}
            </Row> }

            <div className="text-center mt-lg-5">
              <Text variant="tag" className="mb-1" color="lightShade">
                Next Project
              </Text>
              <Link to="portfolio-details">
                <Button
                  arrowRight
                  className="border-0 bg-transparent shadow-none text-capitalize py-3"
                  css={`
                    font-weight: 700
                    font-size: 1.5rem;
                    letter-spacing: -1.2px;
                    line-height: 1.375;
                    @media ${device.md} {
                      font-size: 2rem;
                    }
                `}
                >
                  Replace
                </Button>
              </Link>
            </div>
          </Container>
        </Section> */}
        <Box py={4}>
          <Contact />
        </Box>
      </PageWrapper>
    </>
  )
}

export default CaseStudies
